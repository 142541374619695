import { AnyAction } from 'redux'

import { PaymentMethod } from 'modules/purchase/constants'

import { ISubscription } from 'models/subscriptions.model'

import {
  RESET_3D_SECURE_IFRAME_URL,
  SET_3D_SECURE_IFRAME_URL,
  SET_CREATED_SUBSCRIPTION_ID,
  SET_PAYMENT_CLIENT_SECRET,
  SET_PAYMENT_METHOD,
  SET_SUBSCRIPTION,
  SET_TAX_AMOUNT,
  SET_TRIAL_PERIOD_DAYS,
} from '../actions/common'

export interface IPaymentState {
  selectedSubscription: ISubscription | null
  threeDSecureIframeURL: string
  paymentClientSecret: string
  trialPeriodDays: number
  taxAmount: number
  createdSubscriptionId: string
  paymentMethod: PaymentMethod
}

const initState: IPaymentState = {
  selectedSubscription: null,
  threeDSecureIframeURL: '',
  paymentClientSecret: '',
  trialPeriodDays: 0,
  createdSubscriptionId: '',
  taxAmount: 0,
  paymentMethod: PaymentMethod.APPLE_PAY,
}

export function paymentReducer(
  state: IPaymentState = initState,
  { type, payload = null }: AnyAction,
): IPaymentState {
  switch (type) {
    case SET_SUBSCRIPTION: {
      return {
        ...state,
        selectedSubscription: payload,
      }
    }
    case SET_TAX_AMOUNT: {
      return {
        ...state,
        taxAmount: payload,
      }
    }
    case SET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: payload,
      }
    }
    case RESET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: '',
      }
    }
    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: payload,
      }
    }
    case SET_PAYMENT_CLIENT_SECRET: {
      return {
        ...state,
        paymentClientSecret: payload,
      }
    }
    case SET_TRIAL_PERIOD_DAYS: {
      return {
        ...state,
        trialPeriodDays: payload,
      }
    }
    case SET_CREATED_SUBSCRIPTION_ID: {
      return {
        ...state,
        createdSubscriptionId: payload,
      }
    }
    default:
      return state
  }
}
