import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledFindGuidance = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 32px;
    background: #fafaff;
    min-height: calc(var(--full-height, 100dvh) - 52px);

    & .swiper-slide:nth-child(even) {
      display: flex;
      justify-content: flex-end;
    }

    & .swiper-wrapper {
      transition-timing-function: linear;
    }
  `,

  Card: styled.div`
    max-width: 290px;
    border-radius: 24px;
    border: 2px solid rgba(156, 179, 232, 0.5);
    background: #fafaff;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.GRAY};
    padding: 26px 16px 16px 16px;
    text-align: center;
    position: relative;
  `,
  CardTitle: styled.h4`
    min-width: 231px;
    white-space: nowrap;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.WHITE};
    border-radius: 48px;
    border: 8px solid #fafaff;
    background: #9cb3e8;
    padding: 4px 16px;
    border: 8px solid ${Color.WHITE};
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  `,
  Content: styled.div`
    padding: 24px 0 32px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 24px;
  `,
}
