/* eslint-disable max-lines */
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'

import { IEvent, IEventLogger, TQuestionPageParams } from 'models/events.model'
import { TProductId } from 'models/subscriptions.model'

import { EventLoggerInstanceName, ScreenName } from 'root-constants'

export const enum Events {
  SESSION_STARTED = 'session_start',
  FIRST_PAGE_COMPLETED = 'first_page_completed',
  QUESTION_COMPLETED = 'question_page_completed',
  SALE_SCREEN_SHOW = 'plans_page_show',
  TERMS_OF_USE = 'terms_of_use_tap',
  PRIVACY_POLICY = 'privacy_policy_tap',
  PURCHASE_SHOW = 'subs_purchase_show',
  PAYMENT_METHOD_SELECTED = 'payment_method_selected',
  PAYMENT_METHOD_POPUP_CLOSE = 'payment_method_popup_close',
  PAYMENT_METHOD_TAP = 'change_payment_method_tap',
  PURCHASE_STARTED = 'subs_purchase_started',
  PURCHASE_COMPLETED = 'subs_purchase_completed',
  PURCHASE_FAILED = 'subs_purchase_failed',
  PURCHASE_SCREEN_CLOSED = 'subs_purchase_screen_close',
  FINISH_ACCOUNT_SCREEN_VIEW = 'finish_account_screen_view',
  DOWNLOAD_BTN_PRESSED = 'download_button_press',
  PAYPAL_PAYMENT_POPUP_CLOSE = 'paypal_payment_popup_close',
}

class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []
  private eventsCache: Map<Events, string> = new Map()

  get isAmplitudeActive() {
    return this.loggers?.has(EventLoggerInstanceName.AMPLITUDE)
  }

  get isGIAActive() {
    return this.loggers?.has(EventLoggerInstanceName.GIA)
  }

  init(...loggers: IEventLogger[]): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )

    if (!this.loggers) {
      this.loggers = new Map(entriesArr)
      this.notifyInitFinished()

      return
    }

    if (this.loggers) {
      loggers.map((logger) =>
        this.loggers?.set(
          logger.name as EventLoggerInstanceName,
          logger as IEventLogger,
        ),
      )
    }

    this.notifyInitFinished()
  }

  logSessionStarted(linkLocale: string): void {
    const event = Events.SESSION_STARTED
    const eventProperty = {
      browser_locale_info: navigator.language,
      link_locale_info: linkLocale,
      device_type: getMobileOperatingSystem(),
    }
    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logFirstPageCompleted({ question, answers }: TQuestionPageParams): void {
    const eventProperty = {
      question,
      answer: Array.isArray(answers) ? answers.join(',') : answers,
    }
    this.logEventOrPushToQueue(
      Events.FIRST_PAGE_COMPLETED,
      eventProperty,
      false,
    )
  }

  logQuestion({ question, answers }: TQuestionPageParams): void {
    const event = Events.QUESTION_COMPLETED
    const eventProperty = {
      question,
      answer: Array.isArray(answers) ? answers.join(',') : answers,
    }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logPayPalPaymentPopupClose(): void {
    const event = Events.PAYPAL_PAYMENT_POPUP_CLOSE
    this.logEventOrPushToQueue(event)
  }

  // Sale Page Events
  logSalePageShown({
    productIds,
    screenName = ScreenName.PURCHASE,
  }: {
    productIds: string[]
    screenName?: ScreenName
  }): void {
    const lastLoggedScreen = this.eventsCache.get(Events.SALE_SCREEN_SHOW)

    if (lastLoggedScreen !== screenName) {
      const eventProperty = {
        product_id: productIds.join(','),
        screen_name: screenName,
      }

      this.eventsCache.set(Events.SALE_SCREEN_SHOW, screenName)
      this.logEventOrPushToQueue(Events.SALE_SCREEN_SHOW, eventProperty, false)
    }
  }

  logTermsOfUseClicked(): void {
    const event = Events.TERMS_OF_USE
    this.logEventOrPushToQueue(event)
  }

  logPrivacyPolicyClicked(): void {
    const event = Events.PRIVACY_POLICY
    this.logEventOrPushToQueue(event)
  }

  // Getting App Page Events
  logGettingAppShown(): void {
    const event = Events.FINISH_ACCOUNT_SCREEN_VIEW
    this.logEventOrPushToQueue(event)
  }

  logDownloadClicked(callback: () => void): void {
    const event = Events.DOWNLOAD_BTN_PRESSED
    this.logEventOrPushToQueue(event, {}, true, callback)
  }

  // Purchase Page events
  logPurchaseShown({
    stripeAccountId,
    stripeAccountName,
    productId = '',
    screenName = ScreenName.PURCHASE,
  }: {
    stripeAccountId: string
    stripeAccountName: string
    productId?: TProductId
    screenName?: ScreenName
  }): void {
    const event = Events.PURCHASE_SHOW
    const eventProperty = {
      screen_name: screenName,
      stripe_account_id: stripeAccountId,
      stripe_account_name: stripeAccountName,
      ...(productId && { product_id: productId }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPaymentMethodSelected(paymentMethod: PaymentMethod): void {
    const event = Events.PAYMENT_METHOD_SELECTED
    const eventProperty = {
      payment_method: paymentMethod,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPaymentMethodPopupClose(): void {
    const event = Events.PAYMENT_METHOD_POPUP_CLOSE
    this.logEventOrPushToQueue(event)
  }

  logPaymentMethodTap(): void {
    const event = Events.PAYMENT_METHOD_TAP
    this.logEventOrPushToQueue(event)
  }

  logPurchaseStarted({
    productId,
    priceDetails: { price, trial = false, currency },
    paymentMethod,
    paymentSystem,
    screenName,
    stripeAccountName,
    stripeAccountId,
    isUpgraded = false,
    amountToPay,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency: string }
    paymentMethod: PaymentMethod
    paymentSystem?: PaymentSystem
    screenName: ScreenName
    stripeAccountName: string
    stripeAccountId: string
    isUpgraded?: boolean
    amountToPay?: string
  }): void {
    const event = Events.PURCHASE_STARTED
    const eventProperty = {
      trial,
      price,
      currency,
      product_id: productId,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      screen_name: screenName,
      stripe_account_name: stripeAccountName,
      stripe_account_id: stripeAccountId,
      ...(paymentSystem && {
        payment_system: paymentSystem,
      }),
      ...(isUpgraded && {
        is_upgraded: isUpgraded,
        amount_to_pay: amountToPay,
      }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseCompleted({
    productId,
    priceDetails: { price, trial = false, currency },
    paymentMethod,
    paymentSystem,
    discountApplied,
    screenName,
    stripeAccountName,
    stripeAccountId,
    utmSource,
    isUpgraded = false,
    amountToPay,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency: string }
    paymentMethod?: PaymentMethod
    discountApplied?: string
    screenName: ScreenName
    stripeAccountName: string
    stripeAccountId: string
    utmSource?: string
    isUpgraded?: boolean
    amountToPay?: string
    paymentSystem?: PaymentSystem
  }): void {
    const event = Events.PURCHASE_COMPLETED
    const eventProperty = {
      trial,
      price,
      currency,
      product_id: productId,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      screen_name: screenName,
      stripe_account_name: stripeAccountName,
      stripe_account_id: stripeAccountId,
      ...(paymentSystem && {
        payment_system: paymentSystem,
      }),
      ...(discountApplied && { discount_applied: discountApplied }),
      ...(utmSource && { utm_source: utmSource }),
      ...(isUpgraded && {
        is_upgraded: isUpgraded,
        amount_to_pay: amountToPay,
      }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseFailed({
    productId,
    priceDetails: { price, trial = false, currency },
    error: { description, type, code },
    paymentMethod,
    screenName,
    isUpgraded = false,
    amountToPay,
    stripeAccountName,
    stripeAccountId,
    paymentSystem,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency: string }
    error: { type: string; description?: string; code?: string }
    paymentMethod?: PaymentMethod
    screenName: ScreenName
    isUpgraded?: boolean
    amountToPay?: string
    stripeAccountName: string
    stripeAccountId: string
    paymentSystem?: PaymentSystem
  }): void {
    const event = Events.PURCHASE_FAILED
    const eventProperty = {
      trial,
      price,
      currency,
      error_type: type,
      ...(description && { error_description: description }),
      ...(code && { error_code: code }),
      ...(paymentSystem && { payment_system: paymentSystem }),
      product_id: productId,
      screen_name: screenName,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      stripe_account_name: stripeAccountName,
      stripe_account_id: stripeAccountId,
      ...(isUpgraded && {
        is_upgraded: isUpgraded,
        amount_to_pay: amountToPay,
      }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseScreenClosed({
    productId,
    screenName,
  }: {
    productId: TProductId
    screenName: ScreenName
  }): void {
    const event = Events.PURCHASE_SCREEN_CLOSED
    const eventProperty = {
      product_id: productId,
      screen_name: screenName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  private logEventOrPushToQueue(
    event: Events,
    eventProperty?: Record<string, any>,
    isAmplitudeEvent = true,
    callback?: () => void,
  ): void {
    if (this.isGIAActive) {
      this.logEvent({
        event,
        isAmplitudeEvent,
        eventProperty,
        callback,
      })
    } else {
      this.eventsQueue.push({ event, eventProperty })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(({ event, eventProperty }) =>
        this.logEvent({
          event,
          eventProperty,
        }),
      )
      this.eventsQueue = []
    }
  }

  private logEvent({
    event,
    eventProperty,
    isAmplitudeEvent = true,
    callback,
  }: {
    event: Events
    eventProperty?: Record<string, any>
    isAmplitudeEvent?: boolean
    callback?: () => void
  }): void {
    if (!isAmplitudeEvent) {
      this.loggers
        ?.get(EventLoggerInstanceName.GIA)
        ?.log(event, eventProperty, callback)

      this.loggers
        ?.get(EventLoggerInstanceName.USER_FLOW_TRACKER)
        ?.log(event, eventProperty, callback)
      return
    }

    this.loggers?.forEach((logger) => {
      logger.log(event, eventProperty, callback)
    })
  }
}

export const eventLogger = new EventLoggerService()
