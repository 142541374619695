import { AnyAction } from 'redux'

import {
  RESET_ERROR,
  SET_ANSWERS,
  SET_ANSWERS_FROM_BACKEND,
  SET_APP_CONFIG,
  SET_ERROR,
  SET_IS_PAYMENT_STATUS_SHOWN,
  SET_LANGUAGE,
  SET_PAYMENT_ANIMATION_STATUS,
  SET_PAYMENT_CONFIG,
  SET_SCREEN_NAME,
  SET_SUBSCRIPTION_LIST,
  SET_UTM_TAGS,
  SET_VARIANT,
  START_FETCHING,
  STOP_FETCHING,
} from 'root-redux/actions/common'

import { AnimationState } from 'modules/purchase/constants'

import { TAnswers, TUtmTags } from 'models/common.model'
import { IAppConfig, IPaymentConfig } from 'models/config.model'
import { ISubscription } from 'models/subscriptions.model'
import { IVariant } from 'models/variant.model'

import { ScreenName } from 'root-constants'

const initState: ICommonState = {
  isFetching: false,
  actionList: [],
  error: null,
  currentVariant: null,
  subscriptions: [],
  appConfig: null,
  paymentConfig: null,
  answers: null,
  language: '',
  utmTags: {},
  screenName: ScreenName.PURCHASE,
  isPaymentStatusShown: false,
  paymentAnimationStatus: false,
}

export interface ICommonState {
  isFetching: boolean
  actionList: string[]
  error: any
  currentVariant: IVariant | null
  subscriptions: ISubscription[]
  appConfig: IAppConfig | null
  paymentConfig: IPaymentConfig | null
  answers: TAnswers | null
  language: string
  utmTags: TUtmTags
  screenName: ScreenName
  isPaymentStatusShown: boolean
  paymentAnimationStatus: AnimationState | boolean
}

export function commonReducer(
  state: ICommonState = initState,
  { type, payload = null }: AnyAction,
): ICommonState {
  switch (type) {
    case START_FETCHING: {
      return {
        ...state,
        isFetching: true,
        actionList: state.actionList.concat(payload),
      }
    }
    case STOP_FETCHING: {
      return {
        ...state,
        isFetching: !!payload.length,
        actionList: payload,
      }
    }
    case SET_ERROR: {
      return {
        ...state,
        error: payload,
      }
    }
    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    case SET_VARIANT: {
      return {
        ...state,
        currentVariant: payload,
      }
    }
    case SET_SUBSCRIPTION_LIST: {
      return {
        ...state,
        subscriptions: payload,
      }
    }
    case SET_APP_CONFIG: {
      return {
        ...state,
        appConfig: payload,
      }
    }
    case SET_PAYMENT_CONFIG: {
      return {
        ...state,
        paymentConfig: payload,
      }
    }
    case SET_UTM_TAGS: {
      return {
        ...state,
        utmTags: payload,
      }
    }
    case SET_PAYMENT_ANIMATION_STATUS: {
      return {
        ...state,
        paymentAnimationStatus: payload,
      }
    }
    case SET_IS_PAYMENT_STATUS_SHOWN: {
      return {
        ...state,
        isPaymentStatusShown: payload,
      }
    }
    case SET_ANSWERS: {
      return {
        ...state,
        answers: { ...state.answers, ...payload },
      }
    }
    case SET_ANSWERS_FROM_BACKEND: {
      return {
        ...state,
        answers: payload,
      }
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        language: payload,
      }
    }
    case SET_SCREEN_NAME: {
      return {
        ...state,
        screenName: payload,
      }
    }
    default:
      return state
  }
}
