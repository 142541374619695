import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectFacebookPixelIds } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { COHORT_QUERY_PARAM } from 'root-constants'

export const useFacebookPixelInitialization = (): void => {
  const { search } = document.location
  const uuid = useSelector(selectUUID)
  const facebookPixelIds = useSelector(selectFacebookPixelIds)

  const URLParams = new URLSearchParams(search)
  const cohort = URLParams.get(COHORT_QUERY_PARAM)

  useLayoutEffect(() => {
    if (!window.fbq || !facebookPixelIds.length || !uuid) {
      return
    }

    facebookPixelIds.forEach((facebookPixelId) => {
      window.fbq('init', facebookPixelId, { external_id: uuid })
    })

    window.fbq('track', 'PageView')
  }, [cohort, facebookPixelIds, uuid])
}
