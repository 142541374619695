import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useVatInfo } from 'hooks/useHasVatInfo'

import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { BillingCycle } from 'root-constants'

import { StyledSelectSubscriptionItem as S } from './SelectSubscriptionItem.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
  isOutOfStock?: boolean
}

export const SelectSubscriptionItem: React.FC<TProps> = ({
  subscription: { id, isDefault, currency, mainPrices },
  isSelected,
  isOutOfStock = false,
}) => {
  const { t } = useTranslation()
  const hasVatInfo = useVatInfo()

  const taxAmount = useSelector(selectTaxAmount)

  const getCalculatedPrice = (fullPrice: number) => {
    if (!hasVatInfo) return fullPrice.toFixed(2)

    return getCalculatedPriceWithoutVAT(fullPrice, taxAmount)
  }

  const isYearlyPlan = useMemo(() => {
    return mainPrices.periodQuantity === BillingCycle.TWELVE_MONTHS
  }, [mainPrices.periodQuantity])

  return (
    <S.Wrapper isSelected={isSelected} key={id}>
      <S.Content
        isSelected={isSelected}
        isDefault={isDefault}
        isOutOfStock={isOutOfStock}
      >
        <S.PlanDetails>
          <S.PlanPeriod isSelected={isSelected}>
            <Trans
              i18nKey={
                isYearlyPlan
                  ? 'purchase.subscription.planPeriodYear'
                  : 'purchase.subscription.planPeriodMonth'
              }
              values={{
                count: isYearlyPlan ? 1 : mainPrices.periodQuantity,
              }}
            />
          </S.PlanPeriod>
          {!isSelected && (
            <S.PlanMonthlyPrice isSelected={isSelected}>
              {t('purchase.subscription.monthlyPrice', {
                currency: CURRENCY_SYMBOLS[currency],
                price: getCalculatedPrice(mainPrices.monthly),
              })}
            </S.PlanMonthlyPrice>
          )}
          {isSelected && (
            <S.PlanPrices>
              <S.PlanFullPrice>
                {`${CURRENCY_SYMBOLS[currency]}${getCalculatedPrice(
                  mainPrices.fullPrice,
                )}`}
              </S.PlanFullPrice>
              {isDefault && (
                <S.PlanDiscount>
                  {mainPrices.oldPrices.percentOfDiscount}% off
                </S.PlanDiscount>
              )}
              {isDefault && (
                <S.PlanMonthlyPrice isSelected={isSelected}>
                  {t('purchase.subscription.monthlyPrice', {
                    currency: CURRENCY_SYMBOLS[currency],
                    price: getCalculatedPrice(mainPrices.monthly),
                  })}
                </S.PlanMonthlyPrice>
              )}
              {!isDefault && (
                <S.PlanMonthlyInfo>
                  {t('purchase.subscription.noLongTerm')}
                </S.PlanMonthlyInfo>
              )}
            </S.PlanPrices>
          )}
        </S.PlanDetails>
      </S.Content>
    </S.Wrapper>
  )
}
