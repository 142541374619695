import { AnyAction } from 'redux'

import { IUserStatus } from 'models/user.model'

import {
  SET_STATUS,
  SET_TEST_ENVIRONMENT_QUERY_PARAM,
  SET_UUID,
} from '../actions/user'

export interface IUserState {
  authToken: string
  uuid: string
  status: IUserStatus | null
  testEnvironmentQueryParam: string
}

const initState: IUserState = {
  authToken: '',
  uuid: '',
  status: null,
  testEnvironmentQueryParam: '',
}

export function userReducer(
  state: IUserState = initState,
  { type, payload = null }: AnyAction,
): IUserState {
  switch (type) {
    case SET_UUID: {
      return {
        ...state,
        uuid: payload,
      }
    }
    case SET_STATUS: {
      return {
        ...state,
        status: payload,
      }
    }
    case SET_TEST_ENVIRONMENT_QUERY_PARAM: {
      return {
        ...state,
        testEnvironmentQueryParam: payload,
      }
    }
    default:
      return state
  }
}
