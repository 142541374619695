import React, { Suspense } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill'
import Swiper, { Autoplay, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/bundle'

import { LayoutProvider } from 'components/LayoutProvider'
import { Spinner } from 'components/Spinner'

import {
  selectConfig,
  selectCurrentVariant,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useFacebookPixelInitialization } from 'hooks/useFacebookPixelInitialization'
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics'
import { useInitAppConfig } from 'hooks/useInitAppConfig'
import { useLanguage } from 'hooks/useLanguage'
import { useOutbrainPixelInitialization } from 'hooks/useOutbrainPixelInitialization'
import { useRedditPixelInitialization } from 'hooks/useRedditPixelInitialization'
import { useScrollFix } from 'hooks/useScrollFix'
import { useSetUserStatus } from 'hooks/useSetUserStatus'
import { useSetUuid } from 'hooks/useSetUuid'
import { useSetVariant } from 'hooks/useSetVariant'
import { useSnapPixelInitialization } from 'hooks/useSnapPixelInitialization'
import { useTaboolaPixelInitialization } from 'hooks/useTaboolaPixelInitialization'
import { useTiktokPixelInitialization } from 'hooks/useTiktokPixelInitialization'

import { setViewportFullHeight } from 'helpers/setViewportFullHeight'

import { browserHistory } from 'browser-history'

import { CustomRouter } from './CustomRouter'

setViewportFullHeight()
smoothScrollPolyfill()

Swiper.use([Pagination, Autoplay])

export const App: React.FC = () => {
  const userStatus = useSelector(selectUserStatus, shallowEqual)
  const variant = useSelector(selectCurrentVariant, shallowEqual)
  const config = useSelector(selectConfig)
  const language = useSelector(selectLanguage)

  useInitAppConfig()
  useGoogleAnalytics()
  useSetVariant()
  useLanguage()
  useSetUuid()
  useSetUserStatus()
  useScrollFix()
  useFacebookPixelInitialization()
  useTiktokPixelInitialization()
  useSnapPixelInitialization()
  useOutbrainPixelInitialization()
  useTaboolaPixelInitialization()
  useRedditPixelInitialization()

  return (
    <CustomRouter history={browserHistory}>
      <Suspense fallback={<Spinner />}>
        {userStatus && variant && config && language ? (
          <LayoutProvider />
        ) : (
          <Spinner />
        )}
      </Suspense>
    </CustomRouter>
  )
}
