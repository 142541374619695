/* eslint-disable max-lines */
import {
  PaymentMethodResult,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js'
import { paymentApi, userApi } from 'api'
import i18n from 'i18next'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { sendUserConfigAction } from 'root-redux/actions/user'
import { selectCurrentVariantCohort } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'

import {
  DEFAULT_CARDHOLDER_NAME,
  PaymentMethod,
  PaymentSystem,
} from 'modules/purchase/constants'
import { getStripePurchaseFailedEventParams } from 'modules/purchase/helpers/getStripePurchaseFailedEventParams'
import { getStripePurchaseStartedEventParams } from 'modules/purchase/helpers/getStripePurchaseStartedEventParams'
import { getStripePurchaseSuccessEventParams } from 'modules/purchase/helpers/getStripePurchaseSuccessEventParams'
import {
  checkIsRetryAllowed,
  getRedirectUrl,
  logFailedPayment,
  logSuccessfulPayment,
} from 'modules/purchase/helpers/rootHelpers'

import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import { CENTS_IN_DOLLAR, Cohort } from 'root-constants'

import {
  selectCreatedSubscriptionId,
  selectCurrency,
  selectPaymentClientSecret,
  selectPlanId,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectTrialPeriodDays,
} from '../selects/common'

const MODULE_NAME = 'PURCHASE'

export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`
export const SET_TAX_AMOUNT = `${MODULE_NAME}/SET_TAX_AMOUNT`
export const PURCHASE = `${MODULE_NAME}/PURCHASE`
export const CHECK_3D_SECURE = `${MODULE_NAME}/CHECK_3D_SECURE`
export const SET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/SET_3D_SECURE_IFRAME_URL`
export const RESET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/RESET_3D_SECURE_IFRAME_URL`
export const SET_PAYMENT_CLIENT_SECRET = `${MODULE_NAME}/SET_PAYMENT_CLIENT_SECRET`
export const SET_TRIAL_PERIOD_DAYS = `${MODULE_NAME}/SET_TRIAL_PERIOD_DAYS`
export const SET_CREATED_SUBSCRIPTION_ID = `${MODULE_NAME}/SET_CREATED_SUBSCRIPTION_ID`
export const CHECK_PAYMENT_REQUEST_BUTTON = `${MODULE_NAME}/CHECK_PAYMENT_REQUEST_BUTTON`
export const SET_PAYMENT_METHOD = `${MODULE_NAME}/SET_PAYMENT_METHOD`

const set3DSecureIframeUrlAction = (payload: string): IAction<string> => ({
  type: SET_3D_SECURE_IFRAME_URL,
  payload,
})

const reset3DSecureIframeUrlAction = (): IAction<any> => ({
  type: RESET_3D_SECURE_IFRAME_URL,
})

const setPaymentClientSecretAction = (payload: string): IAction<string> => ({
  type: SET_PAYMENT_CLIENT_SECRET,
  payload,
})

const setTrialPeriodDaysAction = (payload: number): IAction<number> => ({
  type: SET_TRIAL_PERIOD_DAYS,
  payload,
})

const setCreatedSubscriptionIdAction = (payload: string): IAction<string> => ({
  type: SET_CREATED_SUBSCRIPTION_ID,
  payload,
})

const getErrorActionPayload = ({ type, message }: StripeError): string =>
  message || type

export const setSelectedSubscriptionAction = (
  payload: ISubscription,
): IAction<ISubscription> => ({
  type: SET_SUBSCRIPTION,
  payload,
})

export const setTaxAmountAction = (payload: number): IAction<number> => ({
  type: SET_TAX_AMOUNT,
  payload,
})

export const setPaymentMethodAction = (
  payload: PaymentMethod,
): IAction<PaymentMethod> => ({
  type: SET_PAYMENT_METHOD,
  payload,
})

export const purchaseAction =
  ({
    stripe,
    paymentPageId,
    card,
    name,
    createPaymentResFromDigitalWallet,
  }: {
    stripe: Stripe
    paymentPageId: string
    card?: StripeCardNumberElement
    name?: string
    createPaymentResFromDigitalWallet?: PaymentRequestPaymentMethodEvent
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const planId = selectPlanId(state)
    const priceId = selectSubscriptionLookupKey(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)
    const uuid = selectUUID(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const currency = selectCurrency(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const cohort = selectCurrentVariantCohort(state) as Cohort

    if (!priceId || !currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    dispatch(startFetching(PURCHASE))

    const paymentMethod =
      createPaymentResFromDigitalWallet?.paymentMethod?.card?.wallet?.type

    const commonPurchaseStartedParams =
      getStripePurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams =
      getStripePurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

    eventLogger.logPurchaseStarted({
      paymentMethod,
      paymentSystem: PaymentSystem.STRIPE,
      ...commonPurchaseStartedParams,
    })

    try {
      const createPaymentResponse =
        card && !createPaymentResFromDigitalWallet
          ? await stripe.createPaymentMethod({
              type: 'card',
              card,
              billing_details: { name: name || DEFAULT_CARDHOLDER_NAME },
            })
          : (createPaymentResFromDigitalWallet as PaymentMethodResult)

      if (
        !createPaymentResponse?.paymentMethod &&
        createPaymentResponse?.error
      ) {
        const {
          error: { type, code, message },
        } = createPaymentResponse

        eventLogger.logPurchaseFailed({
          error: {
            type,
            code,
            description: message,
          },
          paymentMethod,
          ...commonPurchaseFailedParams,
        })
        dispatch(setErrorAction(message || type))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const createSubscriptionResponse = await paymentApi.createSubscription({
        uuid,
        planId,
        trialPeriodDays,
        cohort,
      })

      if (
        !createSubscriptionResponse.success ||
        !createSubscriptionResponse.data
      ) {
        switch (createSubscriptionResponse.status) {
          case 402:
            dispatch(setErrorAction(i18n.t('purchase1.insufficientFunds')))
            break
          case 404:
            dispatch(setErrorAction(i18n.t('purchase1.linkIsNotValid')))
            break
          case 409:
            dispatch(setErrorAction(i18n.t('purchase1.haveSubscription')))
            break
          default:
            dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
        }

        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          createSubscriptionResponse.data.purchase.client_secret,
        ),
      )
      dispatch(
        setTrialPeriodDaysAction(
          createSubscriptionResponse.data.purchase.trial_period_days,
        ),
      )
      dispatch(
        setCreatedSubscriptionIdAction(
          createSubscriptionResponse.data.purchase.subscription_id,
        ),
      )

      const cardPaymentResponseFirst = await stripe.confirmCardPayment(
        createSubscriptionResponse.data.purchase.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(paymentPageId),
        },
      )

      if (
        !cardPaymentResponseFirst?.paymentIntent &&
        cardPaymentResponseFirst?.error
      ) {
        logFailedPayment({
          paymentResponse: cardPaymentResponseFirst.error,
          paymentMethod,
          ...commonPurchaseFailedParams,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseFirst)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseFirst = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseFirst.error as StripeError,
        })

        if (!retryResponseFirst.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            retryResponseFirst.data.subscription.client_secret,
          ),
        )
        dispatch(
          setTrialPeriodDaysAction(
            retryResponseFirst.data.subscription.trial_period_days,
          ),
        )
        dispatch(
          setCreatedSubscriptionIdAction(
            retryResponseFirst.data.subscription.subscription_id,
          ),
        )

        const cardPaymentResponseSecond = await stripe.confirmCardPayment(
          retryResponseFirst.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseSecond?.paymentIntent &&
          !cardPaymentResponseSecond?.error
        ) {
          const predictedLtvResponse = await userApi.getPredictedLtv(uuid)

          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
            price:
              cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPrice:
              cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
            productId: createSubscriptionResponse.data.purchase.product_id,
            trialPeriodDays:
              retryResponseFirst.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseFirst.data.subscription.subscription_id,
            discountApplied:
              retryResponseFirst.data.subscription.discount_applied,
            paymentMethod,
            paymentSystem: PaymentSystem.STRIPE,
          })
          dispatch(stopFetching(PURCHASE))
          dispatch(
            sendUserConfigAction(
              {
                payment_currency: currency,
                payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
                payment_system: PaymentSystem.STRIPE,
                is_download_visited: false,
                subscription_price: `${currentPrice}`,
                subscription_duration: `${periodQuantity}${periodName}`,
                price_id: priceId,
                trial_price: `${trialPrice}`,
                trial_period: `${trialPeriodDays}`,
                discount_applied:
                  !!retryResponseFirst.data.subscription.discount_applied,
              },
              null,
            ),
          )
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          paymentResponse: cardPaymentResponseSecond.error,
          paymentMethod,
          ...commonPurchaseFailedParams,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseSecond)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseSecond = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseSecond.error as StripeError,
        })

        if (!retryResponseSecond.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            retryResponseSecond.data.subscription.client_secret,
          ),
        )
        dispatch(
          setTrialPeriodDaysAction(
            retryResponseSecond.data.subscription.trial_period_days,
          ),
        )
        dispatch(
          setCreatedSubscriptionIdAction(
            retryResponseSecond.data.subscription.subscription_id,
          ),
        )

        const cardPaymentResponseThird = await stripe.confirmCardPayment(
          retryResponseSecond.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseThird?.paymentIntent &&
          !cardPaymentResponseThird?.error
        ) {
          const predictedLtvResponse = await userApi.getPredictedLtv(uuid)

          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
            price:
              cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPrice:
              cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
            productId: createSubscriptionResponse.data.purchase.product_id,
            trialPeriodDays:
              retryResponseSecond.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseSecond.data.subscription.subscription_id,
            discountApplied:
              retryResponseSecond.data.subscription.discount_applied,
            paymentMethod,
            paymentSystem: PaymentSystem.STRIPE,
          })
          dispatch(stopFetching(PURCHASE))
          dispatch(
            sendUserConfigAction(
              {
                payment_currency: currency,
                payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
                is_download_visited: false,
                subscription_price: `${currentPrice}`,
                subscription_duration: `${periodQuantity}${periodName}`,
                price_id: priceId,
                trial_price: `${trialPrice}`,
                trial_period: `${trialPeriodDays}`,
                payment_system: PaymentSystem.STRIPE,
                discount_applied:
                  !!retryResponseSecond.data.subscription.discount_applied,
              },
              null,
            ),
          )
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          paymentResponse: cardPaymentResponseThird.error,
          paymentMethod,
          ...commonPurchaseFailedParams,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseThird)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseThird = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseThird.error as StripeError,
        })

        if (!retryResponseThird.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            retryResponseThird.data.subscription.client_secret,
          ),
        )
        dispatch(
          setTrialPeriodDaysAction(
            retryResponseThird.data.subscription.trial_period_days,
          ),
        )
        dispatch(
          setCreatedSubscriptionIdAction(
            retryResponseThird.data.subscription.subscription_id,
          ),
        )

        const cardPaymentResponseFourth = await stripe.confirmCardPayment(
          retryResponseThird.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseFourth?.paymentIntent &&
          !cardPaymentResponseFourth?.error
        ) {
          const predictedLtvResponse = await userApi.getPredictedLtv(uuid)

          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
            price:
              cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPrice:
              cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
            productId: createSubscriptionResponse.data.purchase.product_id,
            trialPeriodDays:
              retryResponseThird.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseThird.data.subscription.subscription_id,
            discountApplied:
              retryResponseThird.data.subscription.discount_applied,
            paymentMethod,
            paymentSystem: PaymentSystem.STRIPE,
          })
          dispatch(stopFetching(PURCHASE))
          dispatch(
            sendUserConfigAction(
              {
                payment_currency: currency,
                payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
                is_download_visited: false,
                subscription_price: `${currentPrice}`,
                subscription_duration: `${periodQuantity}${periodName}`,
                price_id: priceId,
                trial_price: `${trialPrice}`,
                trial_period: `${trialPeriodDays}`,
                payment_system: PaymentSystem.STRIPE,
                discount_applied:
                  !!retryResponseThird.data.subscription.discount_applied,
              },
              null,
            ),
          )
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          paymentResponse: cardPaymentResponseFourth.error,
          paymentMethod,
          ...commonPurchaseFailedParams,
        })

        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseFourth.error),
          ),
        )

        // Needed for reset invoice on BE
        await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseFourth.error as StripeError,
        })

        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const { paymentIntent } = cardPaymentResponseFirst

      if (paymentIntent.status === 'requires_payment_method') {
        eventLogger.logPurchaseFailed({
          error: {
            type: 'requires_payment_method',
          },
          paymentMethod,
          ...commonPurchaseFailedParams,
        })
        dispatch(setErrorAction(i18n.t('purchase1.paymentFailed')))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const threeDSecureURL = paymentIntent.next_action?.redirect_to_url?.url

      if (paymentIntent.status === 'requires_action' && threeDSecureURL) {
        dispatch(set3DSecureIframeUrlAction(threeDSecureURL))
        dispatch(stopFetching(PURCHASE))
        return
      }

      const predictedLtvResponse = await userApi.getPredictedLtv(uuid)

      logSuccessfulPayment({
        price: currentPrice,
        predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
        productId: createSubscriptionResponse.data.purchase.product_id,
        trialPeriodDays:
          createSubscriptionResponse.data.purchase.trial_period_days,
        subscriptionId:
          createSubscriptionResponse.data.purchase.subscription_id,
        paymentMethod,
        ...commonPurchaseSuccessParams,
        paymentSystem: PaymentSystem.STRIPE,
      })

      dispatch(stopFetching(PURCHASE))
      dispatch(
        sendUserConfigAction(
          {
            payment_currency: currency,
            payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
            is_download_visited: false,
            subscription_price: `${currentPrice}`,
            subscription_duration: `${periodQuantity}${periodName}`,
            price_id: priceId,
            trial_price: `${trialPrice}`,
            trial_period: `${trialPeriodDays}`,
            payment_system: PaymentSystem.STRIPE,
          },
          null,
        ),
      )
      createPaymentResFromDigitalWallet?.complete('success')
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
    }
  }

export const check3DSecure =
  (stripe: Stripe): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const priceId = selectSubscriptionLookupKey(state)
    const trialPriceId = selectSubscriptionLookupKey(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const clientSecret = selectPaymentClientSecret(state)
    const trialPeriodDays = selectTrialPeriodDays(state)
    const subscriptionId = selectCreatedSubscriptionId(state)
    const currency = selectCurrency(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const trialPeriodQuantity = selectSubscriptionTrialPeriodDays(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)

    if (!priceId || !currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    if (!clientSecret) {
      console.error('Error: client secret is needed')
      return
    }

    dispatch(startFetching(CHECK_3D_SECURE))

    const response = await stripe.retrievePaymentIntent(clientSecret)
    const commonPurchaseSuccessParams =
      getStripePurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

    if (response.paymentIntent?.status === 'succeeded') {
      const predictedLtvResponse = await userApi.getPredictedLtv(uuid)

      logSuccessfulPayment({
        price: currentPrice,
        trialPeriodDays,
        subscriptionId,
        productId: trialPriceId
          ? createIntroOfferProductId({
              priceId,
              trialPriceId,
              trialPeriodQuantity,
            })
          : priceId,
        ...commonPurchaseSuccessParams,
        paymentSystem: PaymentSystem.STRIPE,
        predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
      })

      dispatch(reset3DSecureIframeUrlAction())
      dispatch(
        sendUserConfigAction(
          {
            payment_currency: currency,
            payment_method: PaymentMethod.CREDIT_CARD,
            is_download_visited: false,
            subscription_price: `${currentPrice}`,
            subscription_duration: `${periodQuantity}${periodName}`,
            price_id: priceId,
            trial_price: `${trialPrice}`,
            trial_period: `${trialPeriodDays}`,
          },
          null,
        ),
      )
      return
    }

    if (response.paymentIntent?.status === 'requires_payment_method') {
      eventLogger.logPurchaseFailed({
        error: {
          type: 'requires_payment_method',
        },
        ...commonPurchaseFailedParams,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction(i18n.t('purchase1.paymentFailed')))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    if (response.error) {
      const {
        error: { type, code, message },
      } = response

      eventLogger.logPurchaseFailed({
        error: {
          type,
          code,
          description: message,
        },
        ...commonPurchaseFailedParams,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction(message || type))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction(i18n.t('purchase1.3dSecureError')))
    dispatch(stopFetching(CHECK_3D_SECURE))
  }
