import applePayIcon from 'assets/images/sprite/apple-pay.svg'
import creditCardIcon from 'assets/images/sprite/credit-card.svg'
import googlePayIcon from 'assets/images/sprite/google-pay.svg'

export const enum SubscriptionPeriod {
  MONTHLY = 1,
  QUARTERLY = 3,
  SEMI_ANNUALLY = 6,
  YEARLY = 12,
}

export const enum AnimationState {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const EMPTY_FIELD_ERROR = `can't be blank`

export const DEFAULT_CARDHOLDER_NAME = ' '

export const DEFAULT_ERROR_DESCRIPTION =
  'Please check your payment information or contact your bank and try again, or try a new payment method.'

export const enum StripeErrorCode {
  CARD_DECLINED = 'card_declined',
}

export const enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'pay_pal',
}

export const enum PaymentSystem {
  PAYPAL = 'paypal',
  PRIMER = 'primer',
  STRIPE = 'stripe',
}

export type TCardPaymentSystem = PaymentSystem.PRIMER | PaymentSystem.STRIPE

export const THREEDS_REDIRECT_SEARCH_PARAM = 'threeds'

export const enum StripeSoftDeclineReason {
  AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure',
  DO_NOT_HONOR = 'do_not_honor',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  INVALID_AMOUNT = 'invalid_amount',
  PROCESSING_ERROR = 'processing_error',
  REENTER_TRANSACTION = 'reenter_transaction',
  SERVICE_NOT_ALLOWED = 'service_not_allowed',
  TRANSACTION_NOT_ALLOWED = 'transaction_not_allowed',
  CARD_VELOCITY_EXCEEDED = 'card_velocity_exceeded',
}

export const STRIPE_SOFT_DECLINE_REASONS = [
  StripeSoftDeclineReason.AUTHENTICATION_FAILURE,
  StripeSoftDeclineReason.DO_NOT_HONOR,
  StripeSoftDeclineReason.INSUFFICIENT_FUNDS,
  StripeSoftDeclineReason.INVALID_AMOUNT,
  StripeSoftDeclineReason.PROCESSING_ERROR,
  StripeSoftDeclineReason.REENTER_TRANSACTION,
  StripeSoftDeclineReason.SERVICE_NOT_ALLOWED,
  StripeSoftDeclineReason.TRANSACTION_NOT_ALLOWED,
  StripeSoftDeclineReason.CARD_VELOCITY_EXCEEDED,
]

export const enum Currency {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
  NZD = 'nzd',
  MXN = 'mxn',
}

export const CURRENCY_SYMBOLS = {
  [Currency.USD]: '$',
  [Currency.EUR]: '€',
  [Currency.GBP]: '£',
  [Currency.NZD]: 'NZ$',
  [Currency.MXN]: 'MXN',
}

export const PURCHASE_DISCLAIMER = {
  [SubscriptionPeriod.MONTHLY]: 'purchase.disclaimer.monthlySubscription',
  [SubscriptionPeriod.QUARTERLY]: 'purchase.disclaimer.quarterlySubscription',
  [SubscriptionPeriod.SEMI_ANNUALLY]:
    'purchase.disclaimer.semiAnnuallySubscription',
  [SubscriptionPeriod.YEARLY]: 'purchase.disclaimer.annualSubscription',
}

export const PAYMENT_METHOD_INFO = {
  [PaymentMethod.CREDIT_CARD]: {
    name: 'purchase.payment.displayedPaymentMethodName.creditCard',
    icon: creditCardIcon,
  },
  [PaymentMethod.APPLE_PAY]: {
    name: 'purchase.payment.displayedPaymentMethodName.applePay',
    icon: applePayIcon,
  },
  [PaymentMethod.GOOGLE_PAY]: {
    name: 'purchase.payment.displayedPaymentMethodName.googlePay',
    icon: googlePayIcon,
  },
}
