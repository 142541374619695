import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'

import { resetErrorAction, stopFetching } from 'root-redux/actions/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { PaymentMethod } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  purchaseAction,
  setPaymentMethodAction,
} from 'modules/purchase/redux/actions/common'

import { CENTS_IN_DOLLAR, TRIAL_BILLING_CYCLE } from 'root-constants'

import { StyledPaymentRequestButton as S } from './PaymentRequestButton.styles'

type TProps = {
  buttonHeight?: number
  marginBottom?: number
  borderRadius?: number
  setActivePaymentButton?: (
    setActivePaymentButton: PaymentMethod.APPLE_PAY | PaymentMethod.GOOGLE_PAY,
  ) => void
  isVisible?: boolean
  onSubmit?: () => void
}

export const PaymentRequestButton: React.FC<TProps> = ({
  buttonHeight = 56,
  marginBottom = 0,
  borderRadius = 30,
  setActivePaymentButton,
  isVisible = true,
  onSubmit,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const [paymentRequest, setPaymentRequest] = useState<any>(null)

  const {
    trialPrice,
    trialPeriodDays,
    periodQuantity,
    periodName,
    currency,
    price,
  } = usePurchaseStore()

  const planPeriodDescription = useMemo(() => {
    if (
      !!trialPeriodDays &&
      trialPeriodDays <= TRIAL_BILLING_CYCLE.SEVEN_DAYS
    ) {
      return t('purchase1.paymentForm.trialPlanDescription', {
        trialPeriodDays,
      })
    }

    return t('purchase1.paymentForm.planDescription', {
      periodQuantity,
      periodName,
    })
  }, [periodName, periodQuantity, t, trialPeriodDays])

  const calculatedPrice = useMemo(
    () => +((trialPrice || price) * CENTS_IN_DOLLAR).toFixed(),
    [trialPrice, price],
  )

  const currentPageId = useMemo(
    () => getPageIdFromPathName(pathname),
    [pathname],
  )

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe?.paymentRequest({
      currency,
      country: 'GB',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: planPeriodDescription,
        amount: calculatedPrice,
      },
    })

    pr?.canMakePayment().then((result) => {
      if (!result) dispatch(setPaymentMethodAction(PaymentMethod.CREDIT_CARD))
      if (result) {
        setPaymentRequest(pr)
        const shownButtonType = result.applePay
          ? PaymentMethod.APPLE_PAY
          : PaymentMethod.GOOGLE_PAY
        dispatch(setPaymentMethodAction(shownButtonType))
        setActivePaymentButton && setActivePaymentButton(shownButtonType)
      }
      dispatch(stopFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    })

    pr?.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
        }),
      )

      window.obApi && window.obApi('track', 'Checkout')
      window._tfa &&
        window._tfa.push({ notify: 'event', name: 'start_checkout' })
    })
  }, [
    dispatch,
    stripe,
    elements,
    planPeriodDescription,
    calculatedPrice,
    currentPageId,
    currency,
    setActivePaymentButton,
  ])

  const handleButtonClick = useCallback(() => {
    onSubmit && onSubmit()
  }, [onSubmit])

  return (
    <div>
      {paymentRequest && (
        <S.Root isVisible={isVisible}>
          <S.Wrapper
            marginBottom={marginBottom}
            buttonHeight={buttonHeight}
            borderRadius={borderRadius}
          >
            <PaymentRequestButtonElement
              onClick={handleButtonClick}
              options={{
                paymentRequest,
                style: {
                  paymentRequestButton: {
                    height: `${buttonHeight}px`,
                  },
                },
              }}
            />
          </S.Wrapper>
          <S.Button>{t('actions.continue')}</S.Button>
        </S.Root>
      )}
    </div>
  )
}
