import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { setLanguageAction } from 'root-redux/actions/common'
import { selectCurrentVariant } from 'root-redux/selects/common'

import { browserHistory } from 'browser-history'
import {
  INITIAL_LANGUAGE,
  LANG_QUERY_PARAM,
  SUPPORTED_LOCALES,
} from 'root-constants'

export const useLanguage = (): void => {
  const { pathname, search } = document.location
  const dispatch = useDispatch()
  const variant = useSelector(selectCurrentVariant, shallowEqual)

  useEffect(() => {
    if (!variant) return
    const URLParams = new URLSearchParams(search)
    const langQueryParam = URLParams.get(LANG_QUERY_PARAM) || ''

    if (!SUPPORTED_LOCALES?.includes(langQueryParam)) {
      dispatch(setLanguageAction(INITIAL_LANGUAGE))
      browserHistory.replace({
        pathname,
        search: URLParams.toString(),
      })
      return
    }

    dispatch(setLanguageAction(langQueryParam))
  }, [dispatch, pathname, search, variant])
}
