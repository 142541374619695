import { PaymentSystem, TCardPaymentSystem } from 'modules/purchase/constants'

import { IAppState } from 'models/store.model'
import { IUserStatus } from 'models/user.model'

export const selectUUID = (state: IAppState): string => state.user.uuid
export const selectUserStatus = (state: IAppState): IUserStatus | null =>
  state.user.status
export const selectUserOnboardingEmail = (state: IAppState): string =>
  state.user.status?.email?.onboardingEmail || ''
export const selectUserCountryCode = (state: IAppState): string =>
  state.user.status?.countryCode || ''
export const selectUserPaymentCurrency = (state: IAppState): string =>
  state.user.status?.config.paymentCurrency || ''
export const selectUserPaymentMethod = (state: IAppState): string =>
  state.user.status?.config.paymentMethod || ''
export const selectUserPaymentSystem = (state: IAppState): TCardPaymentSystem =>
  state.user.status?.config.paymentSystem || PaymentSystem.STRIPE
export const selectUserConfigData = (
  state: IAppState,
):
  | Record<
      string,
      string | number | boolean | string[] | Record<string, string>
    >
  | undefined => state.user.status?.config
export const selectGeneralSubscriptionPrice = (state: IAppState): string =>
  state.user.status?.config.subscriptionPrice || ''
export const selectGeneralSubscriptionDuration = (state: IAppState): string =>
  state.user.status?.config.subscriptionDuration || ''
export const selectGeneralSubscriptionPriceId = (state: IAppState): string =>
  state.user.status?.config.priceId || ''
export const selectGeneralSubscriptionTrialPeriod = (
  state: IAppState,
): string => state.user.status?.config.trialPeriod || ''
export const selectGeneralSubscriptionTrialPrice = (state: IAppState): string =>
  state.user.status?.config.trialPrice || ''
export const selectIsDiscountApplied = (state: IAppState): boolean =>
  state.user.status?.config.isDiscountApplied || false
export const selectTestEnvironmentQueryParam = (state: IAppState): string =>
  state.user.testEnvironmentQueryParam
