import { StripeError } from '@stripe/stripe-js'

import { IRequestOptions, IResponseResult } from 'models/api.model'
import {
  IPurchaseRetrySubscriptionResponseRaw,
  ISubscriptionResponseRaw,
} from 'models/payment.model'

import { ApiService } from 'services/api.service'

import { APP_NAME, Cohort, RequestMethod } from 'root-constants'

export class PaymentApi {
  private readonly baseUrl = 'v2/purchases'
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  createSubscription({
    uuid,
    planId,
    trialPeriodDays,
    promoCode,
    cohort,
  }: {
    uuid: string
    planId: number
    promoCode?: string
    trialPeriodDays: number
    cohort: string
  }): Promise<IResponseResult<ISubscriptionResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        plan_id: planId,
        promo_code: promoCode,
        trial_duration: trialPeriodDays,
        cohort,
      },
    }

    return this.api.makeRequest<ISubscriptionResponseRaw>(
      `${this.baseUrl}/stripe/${uuid}/${APP_NAME}`,
      options,
    )
  }

  retryPayment({
    uuid,
    stripeError,
  }: {
    uuid: string
    stripeError: StripeError
  }): Promise<IResponseResult<IPurchaseRetrySubscriptionResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        payment_error: stripeError,
      },
    }
    return this.api.makeRequest<IPurchaseRetrySubscriptionResponseRaw>(
      `${this.baseUrl}/stripe/${uuid}/${APP_NAME}/retry`,
      options,
    )
  }

  createPaypalSubscription({
    uuid,
    paypalPlanId,
    cohort,
  }: {
    uuid: string
    paypalPlanId: string | null
    cohort: Cohort
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        paypal_plan_id: paypalPlanId,
        cohort,
      },
    }
    return this.api.makeRequest<IPurchaseRetrySubscriptionResponseRaw>(
      `${this.baseUrl}/paypal/${uuid}/${APP_NAME}`,
      options,
    )
  }
}
