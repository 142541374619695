import { IRawVariant, IVariant } from 'models/variant.model'

export const getVariantFromRawVariant = (
  rawVariant: IRawVariant,
): IVariant => ({
  cohort: rawVariant.cohort_id,
  parentCohort: rawVariant.parent_cohort_id,
  facebookPixelIds: rawVariant.facebook_pixel_ids,
  growthBookExperiments: rawVariant.optimize_map,
  pinterestTagId: rawVariant.pinterest_tag_id,
  hasCancelOffer: rawVariant.has_cancel_offer,
  hasUpsellOffer: rawVariant.has_upsell_offer,
  hasInAppOffer: rawVariant.has_inapp_offer,
  abSegmentName: rawVariant.ab_segment_name,
  tiktokPixelId: rawVariant.tiktok_pixel_id,
  snapPixelId: rawVariant.snap_pixel_id,
  outbrainPixelId: rawVariant.outbrain_pixel_id,
  taboolaPixelId: rawVariant.taboola_pixel_id,
  redditPixelId: rawVariant.reddit_pixel_id,
  steps: rawVariant.steps.map((step) => ({
    id: step.page_id,
    isPayment: step.is_payment,
    isQuestion: step.is_question,
    isSkippable: step.is_skippable,
    isSubscriptions: step.is_subscriptions,
    hasHeader: step.has_header,
    hasProgressBar: step.has_progressbar,
  })),
})
