import { useSelector } from 'react-redux'

import {
  selectActionList,
  selectCurrentVariantCohort,
  selectHasCancelOffer,
  selectLanguage,
  selectStripeAccountId,
  selectStripeAccountName,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserCountryCode,
  selectUserOnboardingEmail,
  selectUserPaymentSystem,
} from 'root-redux/selects/user'

import {
  select3DSecureIframeUrl,
  selectCurrency,
  selectPaymentMethod,
  selectProductId,
  selectSubscription,
  selectSubscriptionCurrentPriceTax,
  selectSubscriptionDiscountAmount,
  selectSubscriptionDiscountPercentage,
  selectSubscriptionFullPrice,
  selectSubscriptionId,
  selectSubscriptionLookupKey,
  selectSubscriptionOldPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectSubscriptionTrialPriceTax,
  selectTaxAmount,
} from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { TimeInterval } from 'root-constants'

import { PaymentMethod, TCardPaymentSystem } from '../constants'

type TReturnsUseStore = {
  uuid: string
  cohort: string
  periodName: TimeInterval | null
  periodQuantity: number | null
  price: number
  currentPriceTax: number
  trialPriceTax: number
  currency: string
  subscriptions: ISubscription[]
  selectedSubscription: ISubscription | null
  threeDSecureIframeUrl: string
  fetchingActionsList: string[]
  hasCancelOffer: boolean
  trialPrice: number
  fullOldPrice: number
  productId: string
  email: string
  language: string
  selectedSubscriptionId: string
  taxAmount: number
  trialPeriodDays: number
  stripeAccountId: string
  stripeAccountName: string
  discountAmount: number
  discountPercentage: number
  priceLookupKey: string
  paymentMethod: PaymentMethod
  paymentSystem: TCardPaymentSystem
  userCountryCode: string
}

export const usePurchaseStore = (): TReturnsUseStore => {
  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const language = useSelector(selectLanguage)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const price = useSelector(selectSubscriptionFullPrice)
  const currentPriceTax = useSelector(selectSubscriptionCurrentPriceTax)
  const trialPriceTax = useSelector(selectSubscriptionTrialPriceTax)
  const currency = useSelector(selectCurrency)
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscription = useSelector(selectSubscription)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const fetchingActionsList = useSelector(selectActionList)
  const hasCancelOffer = useSelector(selectHasCancelOffer)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const fullOldPrice = useSelector(selectSubscriptionOldPrice)
  const productId = useSelector(selectProductId)
  const email = useSelector(selectUserOnboardingEmail)
  const taxAmount = useSelector(selectTaxAmount)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const discountAmount = useSelector(selectSubscriptionDiscountAmount)
  const discountPercentage = useSelector(selectSubscriptionDiscountPercentage)
  const priceLookupKey = useSelector(selectSubscriptionLookupKey)
  const paymentMethod = useSelector(selectPaymentMethod)
  const paymentSystem = useSelector(selectUserPaymentSystem)
  const userCountryCode = useSelector(selectUserCountryCode)

  return {
    uuid,
    cohort,
    periodName,
    periodQuantity,
    price,
    currency,
    subscriptions,
    selectedSubscription,
    threeDSecureIframeUrl,
    fetchingActionsList,
    hasCancelOffer,
    trialPrice,
    fullOldPrice,
    productId,
    email,
    language,
    selectedSubscriptionId,
    taxAmount,
    trialPeriodDays,
    currentPriceTax,
    trialPriceTax,
    stripeAccountId,
    stripeAccountName,
    discountAmount,
    discountPercentage,
    paymentMethod,
    priceLookupKey,
    paymentSystem,
    userCountryCode,
  }
}
