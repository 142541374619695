import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledButtonBase = css`
  width: 100%;
  height: 56px;
  display: block;
  padding: 0 15px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: ${Color.PRIMARY};
  border-radius: 30px;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  color: ${Color.WHITE};
  transition: background-color 0.2s ease-out;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const PinnedButtonContainer = styled.div`
  width: 100%;
  max-width: 375px;
  margin: auto auto 0;
  padding: 0 20px;
`
