import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectCurrentVariantSteps } from 'root-redux/selects/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { PageId } from 'page-constants'

export const useGetPageInfo = () => {
  const { pathname } = useLocation()
  const steps = useSelector(selectCurrentVariantSteps)

  const currentPageId = getPageIdFromPathName(pathname)

  const currentPageIndex = steps.findIndex(({ id }) => id === currentPageId)

  const hasHeader =
    steps[currentPageIndex]?.hasHeader || currentPageId === PageId.DOWNLOAD

  const currentPaywallPageId = steps.find((page) => page.isSubscriptions)
    ?.id as PageId

  return {
    currentPageId,
    hasHeader,
    currentPaywallPageId,
  }
}
