import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StyledCustomerReviews as S } from './Review.styles'

type TProps = {
  icon: string
  name: string
  review: string
}

export const Review: React.FC<TProps> = ({ icon, name, review }) => {
  const { t } = useTranslation()

  return (
    <S.ReviewContainer>
      <S.CustomerInfoContainer>
        <S.CustomerImg src={icon} alt="customer" />
        <S.CustomerName>{t(name)}</S.CustomerName>
        <S.CustomerRecommendation>
          <Trans
            i18nKey="onboarding.worldwideCommunity.recommendation"
            components={[<strong />]}
          />
        </S.CustomerRecommendation>
      </S.CustomerInfoContainer>
      <S.CustomerReview>
        <Trans i18nKey={review} />
      </S.CustomerReview>
    </S.ReviewContainer>
  )
}
