import styled from 'styled-components'

import { Button } from 'components/Button'

import checkIcon from 'assets/images/check-icon-blue.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseV2 = {
  Wrapper: styled.div`
    padding-bottom: 40px;
    background-color: #fafaff;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 360px;
      margin: 0 auto;
    }
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;

    & strong {
      font-weight: 700;
      color: ${Color.MIDNIGHT_BLUE};
    }
  `,
  Cards: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 11px;
    margin-bottom: 32px;

    & p {
      padding: 12px 8px;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 123.077% */
      letter-spacing: 0.4px;
      color: ${Color.BLUE_DARK};
      text-align: center;
    }
  `,
  Card: styled.div`
    border-radius: 12px;
    background: ${Color.WHITE};
    overflow: hidden;
    border: 1px solid ${Color.CORAL_GRAY};
  `,
  ActiveCard: styled.div`
    border-radius: 12px;
    border: 3px solid ${Color.MIDNIGHT_BLUE};
    background: ${Color.WHITE};
    overflow: hidden;
  `,
  BenefitsTitle: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 16px;
    text-align: center;
  `,
  Benefits: styled.ul`
    margin-bottom: 32px;
  `,
  Benefit: styled.li`
    list-style: none;
    position: relative;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding-left: 32px;
    margin-bottom: 16px;
    color: ${Color.GRAY};

    & strong {
      color: ${Color.BLUE_DARK};
    }

    &::before {
      width: 24px;
      height: 24px;
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url(${checkIcon}) no-repeat center;
      background-size: 18px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
  TimerContainer: styled.div`
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 10;
  `,
  TimerContent: styled.div`
    margin: 0 auto;
    max-width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
  `,
  TimerText: styled.div`
    color: ${Color.DARK_SECONDARY};
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 127.273% */
    letter-spacing: 0.5px;
  `,
  CountdownTime: styled.p`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${Color.DARK_SECONDARY};
    line-height: 28px;
    height: 28px;
  `,
  TopButton: styled(Button)`
    width: 50%;
    border-radius: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  `,
  ContainerWrapper: styled.div`
    max-width: 360px;
    margin: 0 auto 16px;
    padding: 0 12px;
  `,
  SubscriptionsContainer: styled.div``,
  ButtonContainer: styled.div`
    margin: -6px 0 24px;
  `,
  NumberOneImage: styled.img`
    width: 288px;
    margin: 0 auto 24px;
  `,
  Subtitle: styled.h2`
    color: ${Color.DARK};
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
  `,
  DuplicateTimer: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    padding: 6px 10px 10px;
    background: linear-gradient(270deg, #66b252 0%, #81cd6c 103.03%);
    margin-bottom: 24px;
  `,
  DuplicateTimerText: styled.span`
    color: ${Color.WHITE};
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    strong {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  `,
  Disclaimer: styled.p`
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: ${Color.GRAY};
    text-align: center;
    margin-bottom: 40px;

    & a {
      color: ${Color.GRAY};
      text-decoration: underline;
    }
  `,
}
