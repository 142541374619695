import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  getSubscriptionListAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectScreenName } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import checkIconSvg from 'assets/images/sprite/check-icon-orange.svg'

import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { CardPaymentForm } from '../components/CardPaymentForm'
import { PaymentRequestButton } from '../components/PaymentRequestButton'
import { PaymentWaitingModal } from '../components/PaymentWaitingModal'
import { SelectPaymentMethod } from '../components/SelectPaymentMethod'
import { SubscriptionsBlock } from '../components/SubscriptionsBlock'
import {
  CURRENCY_SYMBOLS,
  PAYMENT_METHOD_INFO,
  PURCHASE_DISCLAIMER,
  PaymentMethod,
} from '../constants'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseStore,
} from '../hooks'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setPaymentMethodAction,
} from '../redux/actions/common'
import { StyledPurchase as S } from './Purchase.styles'

export const Purchase = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)
  const [isPaymentMethodSelectVisible, setIsPaymentMethodSelectVisible] =
    useState(false)
  const [activePaymentButton, setActivePaymentButton] = useState<
    PaymentMethod.APPLE_PAY | PaymentMethod.GOOGLE_PAY | null
  >(null)
  const screenName = useSelector(selectScreenName)

  const {
    selectedSubscription,
    threeDSecureIframeUrl,
    uuid,
    stripeAccountId,
    stripeAccountName,
    subscriptions,
    paymentMethod,
    fetchingActionsList,
    periodQuantity,
    price,
    currency,
  } = usePurchaseStore()

  const { arePricesReady } = usePricesStatus()

  const isPaymentRequestButtonActive = useMemo(() => {
    return fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON)
  }, [fetchingActionsList])

  const disclaimerTextPath = useMemo(() => {
    if (!periodQuantity) return ''

    return PURCHASE_DISCLAIMER[periodQuantity]
  }, [periodQuantity])

  useLayoutEffect(() => {
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        SubscriptionTagsType.NO_TAX,
      ),
    )
  }, [dispatch])

  useDefaultSubscription()

  useEffect(() => {
    dispatch(setScreenNameAction(ScreenName.PURCHASE))
  }, [dispatch])

  useEffect(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
  }, [dispatch])

  useEffect(() => {
    if (!uuid) return
    window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
  }, [uuid])

  useEffect(() => {
    if (!subscriptions.length) return
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  }, [subscriptions])

  useEffect(() => {
    eventLogger.logPurchaseShown({
      screenName,
      stripeAccountId,
      stripeAccountName,
    })
  }, [screenName, stripeAccountId, stripeAccountName])

  const toggleSelectPaymentMethodVisibility = useCallback(() => {
    setIsPaymentMethodSelectVisible(!isPaymentMethodSelectVisible)
  }, [isPaymentMethodSelectVisible])

  const handlePaymentMethodChange = useCallback(
    (event) => {
      eventLogger.logPaymentMethodSelected(event.target.value)
      dispatch(setPaymentMethodAction(event.target.value))
      toggleSelectPaymentMethodVisibility()
    },
    [dispatch, toggleSelectPaymentMethodVisibility],
  )

  const sendEvents = useCallback(() => {
    googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)
    window.fbq('track', 'AddToCart', {}, { eventID: uuid })
  }, [uuid, selectedSubscription])

  return (
    <div>
      {!arePricesReady ? (
        <Spinner />
      ) : (
        <S.Root>
          {threeDSecureIframeUrl ? (
            <S.ThreeDSecureIframe
              title="3DSecure"
              src={threeDSecureIframeUrl}
            />
          ) : (
            <Container>
              <S.Title>{t('purchase.title')}</S.Title>
              <S.List>
                <S.ListItem>
                  <SvgImage svg={checkIconSvg} width={18} />
                  {t('purchase.benefit1')}
                </S.ListItem>
                <S.ListItem>
                  <SvgImage svg={checkIconSvg} width={18} />
                  {t('purchase.benefit2')}
                </S.ListItem>
                <S.ListItem>
                  <SvgImage svg={checkIconSvg} width={18} />
                  {t('purchase.benefit3')}
                </S.ListItem>
                <S.ListItem>
                  <SvgImage svg={checkIconSvg} width={18} />
                  {t('purchase.benefit4')}
                </S.ListItem>
              </S.List>
              <SubscriptionsBlock />

              {!isPaymentRequestButtonActive && (
                <S.PaymentMethod>
                  <S.PaymentMethodName>
                    <SvgImage
                      svg={PAYMENT_METHOD_INFO[paymentMethod].icon}
                      width={36}
                    />
                    {t(PAYMENT_METHOD_INFO[paymentMethod].name)}
                  </S.PaymentMethodName>
                  {activePaymentButton && (
                    <S.SwitchPaymentMethod
                      onClick={() => {
                        eventLogger.logPaymentMethodTap()
                        toggleSelectPaymentMethodVisibility()
                      }}
                    >
                      {t(
                        'purchase.subscription.paymentMethod.pickPaymentMethod',
                      )}
                    </S.SwitchPaymentMethod>
                  )}
                </S.PaymentMethod>
              )}

              <S.PaymentContainer>
                <PaymentRequestButton
                  isVisible={
                    paymentMethod === PaymentMethod.APPLE_PAY ||
                    paymentMethod === PaymentMethod.GOOGLE_PAY
                  }
                  setActivePaymentButton={setActivePaymentButton}
                  key={selectedSubscription?.id}
                  onSubmit={sendEvents}
                />

                <CardPaymentForm
                  isCardPaymentShown={
                    paymentMethod === PaymentMethod.CREDIT_CARD
                  }
                  onSubmit={sendEvents}
                />
              </S.PaymentContainer>
              <S.Disclaimer>
                <Trans
                  i18nKey={disclaimerTextPath}
                  values={{
                    price,
                    currency: CURRENCY_SYMBOLS[currency],
                    context: currency,
                  }}
                  components={[<br />]}
                />
                <TermsOfUseLink />.
              </S.Disclaimer>
            </Container>
          )}

          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />

          {isPaymentRequestButtonActive && <Spinner />}

          {isPaymentMethodSelectVisible && (
            <SelectPaymentMethod
              onChange={handlePaymentMethodChange}
              onClose={toggleSelectPaymentMethodVisibility}
              activePaymentButton={activePaymentButton}
            />
          )}
        </S.Root>
      )}
    </div>
  )
}
