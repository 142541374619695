import React from 'react'

import { TComponentProps } from 'models/common.model'

import { StyledContainer as S } from './Container.styles'

export type TContainer = {
  fields?: number
}

export const Container: React.FC<TComponentProps & TContainer> = ({
  children,
  className = '',
  fields = 0,
}) => (
  <S.Container className={className} fields={fields}>
    {children}
  </S.Container>
)
