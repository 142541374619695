import styled from 'styled-components'

import earthIcon from 'assets/images/customer-review-earth-icon.png'
import messageIcon from 'assets/images/customer-review-icon.png'

import { Color } from 'root-constants'

export const StyledCustomerReviews = {
  ReviewContainer: styled.div`
    margin-bottom: 10px;
    padding: 12px;
    border-radius: 16px;
    background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
    box-shadow: 0 8px 20px 0 #e8e8e8;

    &:last-of-type {
      margin-bottom: 25px;
    }
  `,
  CustomerInfoContainer: styled.div`
    position: relative;
    padding-left: 55px;
  `,
  CustomerImg: styled.img`
    position: absolute;
    top: 0;
    left: 0;
    max-width: 40px;
  `,
  CustomerName: styled.p`
    position: relative;
    display: inline-block;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #7590ce;

    &:before {
      content: '';
      position: absolute;
      top: 3px;
      right: -20px;
      width: 13px;
      height: 13px;
      background-image: url(${messageIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }
  `,
  CustomerRecommendation: styled.p`
    position: relative;
    padding: 0 0 7px 16px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #a9a9a9;

    & strong {
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.5px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 12px;
      height: 12px;
      background-image: url(${earthIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }
  `,
  CustomerReview: styled.p`
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: ${Color.BLUE_DARK};
  `,
  EmojiContainer: styled.span`
    margin-left: 2px;
    vertical-align: middle;
  `,
  Emoji: styled.img`
    display: inline-block;
    width: 16px;
    height: 16px;
  `,
}
