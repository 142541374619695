import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { VAT_INCLUDED_COUNTRY } from 'root-constants'

export const useVatInfo = (): boolean => {
  const countryCode = useSelector(selectUserCountryCode)

  return useMemo(() => {
    return VAT_INCLUDED_COUNTRY.includes(countryCode)
  }, [countryCode])
}
