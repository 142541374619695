import CustomerReview1 from 'assets/images/customer-photo-1.png'
import CustomerReview2 from 'assets/images/customer-photo-2.png'
import CustomerReview3 from 'assets/images/customer-photo-3.png'

export const DEFAULT_CUSTOMER_REVIEWS = [
  {
    name: 'onboarding.worldwideCommunity.firstUserName',
    icon: CustomerReview1,
    review: 'onboarding.worldwideCommunity.firstUserReview',
  },
  {
    name: 'onboarding.worldwideCommunity.secondUserName',
    icon: CustomerReview2,
    review: 'onboarding.worldwideCommunity.secondUserReview',
  },
  {
    icon: CustomerReview3,
    name: 'onboarding.worldwideCommunity.thirdUserName',
    review: 'onboarding.worldwideCommunity.thirdUserReview',
  },
]
export const QUESTION = 'Join the Worldwide Community of Christians'
