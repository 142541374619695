import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'

import { resetErrorAction, stopFetching } from 'root-redux/actions/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { PaymentMethod } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  purchaseAction,
} from 'modules/purchase/redux/actions/common'
import { TPaymentRequestButton } from 'modules/purchase/types'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { CENTS_IN_DOLLAR, TRIAL_BILLING_CYCLE } from 'root-constants'

import { StyledStripeSeparatePaymentRequestButton as S } from './StripeSeparatePaymentRequestButton.styles'

type TProps = {
  buttonHeight?: number
  borderRadius?: number
  setAlternativePaymentMethodCallback?: (
    paymentMethod: PaymentMethod.APPLE_PAY | PaymentMethod.GOOGLE_PAY,
  ) => void
  setAlternativePaymentMethodsCallback?: Dispatch<
    SetStateAction<PaymentMethod[]>
  >
}

export const StripeSeparatePaymentRequestButton: React.FC<TProps> = ({
  buttonHeight = 55,
  borderRadius = 12,
  setAlternativePaymentMethodCallback,
  setAlternativePaymentMethodsCallback,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const [paymentRequest, setPaymentRequest] = useState<any>(null)
  const [buttonTypes, setButtonTypes] = useState<TPaymentRequestButton | null>(
    null,
  )

  const {
    selectedSubscription,
    uuid,
    trialPrice,
    trialPeriodDays,
    periodQuantity,
    periodName,
    currency,
    price,
  } = usePurchaseStore()

  const planPeriodDescription = useMemo(() => {
    if (
      !!trialPeriodDays &&
      trialPeriodDays <= TRIAL_BILLING_CYCLE.SEVEN_DAYS
    ) {
      return t('purchase1.paymentForm.trialPlanDescription', {
        trialPeriodDays,
      })
    }

    return t('purchase1.paymentForm.planDescription', {
      periodQuantity,
      periodName,
    })
  }, [periodName, periodQuantity, t, trialPeriodDays])

  const calculatedPrice = useMemo(
    () => +((trialPrice || price) * CENTS_IN_DOLLAR).toFixed(),
    [trialPrice, price],
  )

  const currentPageId = useMemo(
    () => getPageIdFromPathName(pathname),
    [pathname],
  )

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe?.paymentRequest({
      currency,
      country: 'GB',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: planPeriodDescription,
        amount: calculatedPrice,
      },
    })

    pr?.canMakePayment().then((result) => {
      if (result) {
        setButtonTypes(result as TPaymentRequestButton)
        setPaymentRequest(pr)

        if (setAlternativePaymentMethodsCallback) {
          result.applePay
            ? setAlternativePaymentMethodsCallback((prevValue) => [
                ...prevValue,
                PaymentMethod.APPLE_PAY,
              ])
            : setAlternativePaymentMethodsCallback((prevValue) => [
                ...prevValue,
                PaymentMethod.GOOGLE_PAY,
              ])
        }

        if (setAlternativePaymentMethodCallback) {
          result.applePay
            ? setAlternativePaymentMethodCallback(PaymentMethod.APPLE_PAY)
            : setAlternativePaymentMethodCallback(PaymentMethod.GOOGLE_PAY)
        }
      }
      dispatch(stopFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    })

    pr?.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
        }),
      )

      window.obApi && window.obApi('track', 'Checkout')
      window._tfa &&
        window._tfa.push({ notify: 'event', name: 'start_checkout' })
      googleAnalyticsLogger.logAddingToCart(
        selectedSubscription as ISubscription,
      )
      window.fbq('track', 'AddToCart', {}, { eventID: uuid })
    })
  }, [
    dispatch,
    stripe,
    elements,
    planPeriodDescription,
    calculatedPrice,
    currentPageId,
    currency,
    setAlternativePaymentMethodCallback,
    setAlternativePaymentMethodsCallback,
    selectedSubscription,
    uuid,
  ])

  const handleButtonClick = useCallback(() => {
    const shownButtonType = buttonTypes?.applePay
      ? PaymentMethod.APPLE_PAY
      : PaymentMethod.GOOGLE_PAY

    eventLogger.logPaymentMethodSelected(shownButtonType)
  }, [buttonTypes])

  return (
    <div>
      {paymentRequest && (
        <S.Wrapper buttonHeight={buttonHeight} borderRadius={borderRadius}>
          <PaymentRequestButtonElement
            onClick={handleButtonClick}
            options={{
              paymentRequest,
              style: {
                paymentRequestButton: {
                  height: `${buttonHeight}px`,
                },
              },
            }}
          />
        </S.Wrapper>
      )}
    </div>
  )
}
