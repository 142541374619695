import { IntroVariant1 } from 'pages/intro'
import { FindGuidance, ListenToBible, Reviews } from 'pages/questions'

import { Purchase, PurchaseV2 } from 'modules/purchase/pages'

import { TIdToPageMap } from 'models/common.model'

export const enum PageId {
  INTRO = 'intro',
  FIND_GUIDANCE = 'findGuidance',
  LISTEN_STORIES = 'listenStories',
  REVIEWS = 'reviews',
  PURCHASE = 'purchase',
  PURCHASE_V2 = 'purchaseV2',
  DOWNLOAD = 'download',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  [PageId.INTRO]: IntroVariant1,
  [PageId.FIND_GUIDANCE]: FindGuidance,
  [PageId.LISTEN_STORIES]: ListenToBible,
  [PageId.REVIEWS]: Reviews,
  [PageId.PURCHASE]: Purchase,
  [PageId.PURCHASE_V2]: PurchaseV2,
}
