import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import i18n from 'i18next'

import { Header } from 'components/Header'

import {
  selectCurrentVariantCohort,
  selectLanguage,
  selectScreenName,
} from 'root-redux/selects/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useEventLoggerInitialization } from 'hooks/useEventLoggerInitialization'
import { useGAPageTracker } from 'hooks/useGAPageTracker'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useTestEnvQueryParameter } from 'hooks/useTestEnvQueryParameter'
import { useUtmTags } from 'hooks/useUtmTags'

import { Cohort } from 'root-constants'

import { RouteList } from '../../RouteList'

export const LayoutProvider: React.FC = () => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const language = useSelector(selectLanguage)
  const screenName = useSelector(selectScreenName)

  const { hasHeader } = useGetPageInfo()

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  useUtmTags()
  useGAPageTracker()
  useEventLoggerInitialization(cohort as Cohort)
  useAmplitudeInitialization(cohort as Cohort, screenName)
  useScrollToTop()
  useTestEnvQueryParameter()

  return (
    <>
      {hasHeader && <Header />}
      <RouteList />
    </>
  )
}
